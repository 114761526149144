import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogPost from "../components/blog-post"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <section className=" u-mb">
        <h1>Hi, I'm Dan</h1>
        <div className="o-layout">
          <div className=" o-layout__item u-1-of-1-mobile u-1-of-4-tablet u-mb">
            <Img
              fluid={data.image.childImageSharp.fluid}
              alt="Dan Garland"
              className="b--thin b--solid b-light-grey u-1-of-2-mobile u-1-of-1-tablet l-centered"
            />
          </div>

          <div className="o-layout__item u-1-of-1-mobile u-3-of-4-tablet">
            <p>
              After graduating in Computer Science, I have nearly{" "}
              <Link className="link" to="/experience">
                {" "}
                twenty years experience
              </Link>{" "}
              in analysing, developing and delivering web applications. I have a
              passion for creating tech businesses: the product and the teams
              that drive them. In 2013 I founded my own software consultancy and
              training business, providing over 80 developers with their frst
              web development job. I am a creative person and enjoy the
              challenge of applying technology to realworld problems. building
              software for start-ups, SMEs and enterprise.
            </p>

            <p>I specialise in full-stack web development:</p>

            <ol>
              <li>
                Agile web development with Ruby on Rails and JavaScript (Node.js
                / React)
              </li>
              <li>Minimum Viable Product (MVP) design and build</li>
              <li>Requirements analysis with User Stories</li>
              <li>
                Training: workshops, immersive bootcamp courses and live-coding
              </li>
            </ol>

            <p>
              Looking for your next Lead Developer, CTO or Tech Co-Founder?
              <Link
                to="/hire-me"
                className="c-btn c-btn--primary c-btn--small l-inline-block u-m-"
              >
                Hire Me
              </Link>
            </p>
          </div>
        </div>
      </section>

      <section className=" u-pb++">
        <h2>Articles</h2>
        <div className="o-layout">
          {data.allMarkdownRemark.edges.map((edge, i) => (
            <BlogPost
              key={i}
              {...edge.node.frontmatter}
              excerpt={edge.node.excerpt}
            />
          ))}
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query RecentBlogPosts {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog_posts/" } }
      limit: 6
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          frontmatter {
            created_at: date(formatString: "dddd, Do MMMM, YYYY")
            date_path: date(formatString: "YYYY/MM/DD")
            image {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            image_crop
            image_caption
            image_alt
            updated_at: lastmod
            published
            slug
            title
            tags
          }

          excerpt
        }
      }
    }
    image: file(relativePath: { eq: "me.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
