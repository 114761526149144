import React from "react"
import { Link } from "gatsby"

import Img from "gatsby-image"
const BlogPost = ({
  created_at,
  date_path,
  slug,
  title,
  excerpt,
  image,
  image_alt,
  image_crop
}) => {
  return (
    <div className="o-layout__item u-1-of-1-mobile u-1-of-3-tablet">
      <Link to={"/" + [date_path, `${slug}.html`].join("/")}>
        <Img
          fluid={image.childImageSharp.fluid}
          alt={image_alt}
          className="u-mb"
        />
      </Link>
      <h3>{title}</h3>
      <p>{excerpt}</p>
    </div>
  )
}

export default BlogPost
